.skw-pages {
    overflow: hidden;
    position: relative;
    height: 100vh;
  }
  
  $skewDeg: 18deg;
  $magicVH: 32.4vh; 
  $scrollTime: 1s;
  
  .skw-page {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  
    &__half {
      position: absolute;
      top: 0;
      height: 100vh;
      @media screen and (min-width: 1200px) {
        width: 50%;
      }
     /* transition: transform $scrollTime; */
  
      &--left {
        left: 0;
        transform: translate3d(-$magicVH, 100%, 0);
      }
  
      &--right {
        @media screen and (min-width: 1200px) {
          left: 50%;
        }
        transform: translate3d($magicVH, -100%, 0);
      }
  
      .skw-page.active & {
        transform: translate3d(0,0,0);
      }
    }
  
    &__skewed {
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 140%;
      height: 100%;
      transform: skewX($skewDeg * -1);
      background: #000;
  
      .skw-page__half--left & {
        @media screen and (min-width: 1200px) {
          left: -40%;
        }
      }
      .skw-page__half--right & {
        @media screen and (min-width: 1200px) {
          right: -40%;
        }
      }
    }
  
    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column wrap;
      position: absolute;
      left: -6%;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 0 30%;
      color: #fff;
      transform: skewX($skewDeg);
      //transition: transform $scrollTime, opacity $scrollTime;
      background-size: cover;
      @media screen and (min-width: 1200px) {
        left: 0;
      }
  
      .skw-page__half--left & {
        padding-left: 30%;
        padding-right: 30%;
        transform-origin: 100% 0;
      }
      .skw-page__half--right & {
        padding-left: 30%;
        padding-right: 30%;
        transform-origin: 0 100%;
      }
    }
  
    &__heading {
      margin-bottom: 15px;
      text-transform: uppercase;
      font-size: 25px;
      text-align: center;
    }
  
    &__description {
      font-size: 18px;
      text-align: center;
    }
    &-1 {
      .skw-page__half--left .skw-page__content {
        background-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/142996/mousover-img-1.jpg');
      }
      .skw-page__half--right .skw-page__content {
        background: lighten(#555, 5%);
      }
    }
  }
  